{
  "global": {
    "language": "German",
    "actionClose": "Schließen",
    "actionBack": "Zurück",
    "actionCancel": "Abbrechen",
    "optional": "optional",
    "head": {
      "title": "@:brand.name",
      "description": "Ehrliche Tickets kaufen oder weiterverkaufen? Dank Blockchain-Technologie eliminiert @:brand.nameShort Ticket-Betrug und exorbitante Preise. Für jedes Event. Von Theater über Konzerte bis zu Sportveranstaltungen: mit @:brand.nameShort ist der Kauf und Verkauf von Tickets simpel, transparent und sicher.",
      "twitter": "@:brand.twitter",
      "event": {
        "title": "Tickets für {title}",
        "open": "Bestelle deine ehrlichen Tickets für {title}, {date} in {location} über @:brand.name",
        "queue": "Bestelle deine ehrlichen Tickets für {title} über @:brand.name",
        "resale": "Schau dir alle Tickets auf unserem Ticketmarkt für {title}, {date} in {location} über @:brand.name an",
        "reseller": "Meine Tickets stehen zum Verkauf für {title}, {date} in {location} unter @:brand.name. Hol sie dir, solange du kannst!"
      }
    },
    "fullscreen": "Vollbild für ein besseres Erlebnis",
    "cookiesConsent": {
      "description": "Wir verwenden Cookies für ein besseres Erlebnis.",
      "actionAccept": "Cookies akzeptieren",
      "actionMore": {
        "name": "Mehr erfahren"
      }
    },
    "logout": {
      "logoutText": "Abmelden",
      "logoutConfirmText": "Bist du sicher? Wenn du dich erneut anmeldest, erhältst du einen neuen Zugangscode per SMS."
    },
    "navigation": {
      "account": "Konto",
      "eventList": "Events",
      "myTickets": "Meine Tickets",
      "login": "Anmelden",
      "profile": "Profil"
    }
  },
  "brand": {
    "name": "#.name",
    "nameShort": "#.nameShort",
    "twitter": "#.twitter",
    "homepageUrl": "#.homepageUrl",
    "homepageUrlShort": "#.homepageUrlShort",
    "supportUrl": "#.supportUrl",
    "livestreamSupportUrl": "#.livestreamSupportUrl",
    "privacyPolicyUrl": "#.privacyPolicyUrl",
    "termsUrl": "#.termsUrl",
    "androidAppstoreUrl": "#.androidAppstoreUrl",
    "appleAppstoreUrl": "#.appleAppstoreUrl"
  },
  "validation": {
    "errors": {
      "required": "Dieses Feld ist erforderlich",
      "email": "Diese E-Mail-Adresse scheint ungültig zu sein",
      "phoneNumber": "Dies ist keine gültige Handynummer",
      "rateLimit": {
        "message": "Zu viele Verifizierungsversuche.",
        "minutes": "Bitte versuche es in 2 Minuten erneut.",
        "seconds": "Bitte versuche es in {seconds} Sekunden erneut."
      },
      "globalBlock": {
        "message": "Too many verification attempts.",
        "minutes": "Please try again in a few minutes.",
        "seconds": "Please try again in {seconds} seconds."
      },
      "birthdate": "Dieses Geburtsdatum scheint ungültig zu sein"
    }
  },
  "messages": {
    "saveProfile": "Profil erfolgreich gespeichert!",
    "seated": "Sitzplatz-Tickets betsellt? Du erhältst deine Sitznummern mindestens 24 Stunden vor Eventbeginn per E-Mail und in der @:brand.nameShort App!",
    "seatsAssigned": "Aufgrund der begrenzten Anzahl der verfügbaren Tickets können wir nicht garantieren, dass deine Sitzplätze nebeneinander liegen."
  },
  "errors": {
    "default": "Hoppla! Etwas ist schiefgelaufen...<br/>Du könntest diese Seite <a onClick='window.location.reload(false)'>aktualisieren</a> und es erneut versuchen.",
    "notStarted": "Der Ticketverkauf hat noch nicht begonnen. Überprüfe, ob dein Computer/Smartphone auf das richtige Datum und die richtige Zeit eingestellt ist.",
    "invalidURL": "Fehler: Zugangscode ist ungültig oder bereits verwendet.",
    "saveProfile": "Fehler: Profil konnte nicht gespeichert werden.",
    "exceeding_limits": "Du überschreitest die maximale Anzahl an Tickets, die du kaufen darfst.",
    "token_different_user": "Wenn du dich mit einer anderen Handynummer angemeldet hast als der, die du bei der Anmeldung in der Warteschlange angegeben hast, benutze bitte die Schaltfläche \"Aktualisieren\", um dich mit der neuen, korrekten Nummer erneut anzumelden.",
    "token_expired": "Deine Sitzung für diesen Shop ist abgelaufen. Bitte benutze den Aktualisieren-Button, um dich erneut anzumelden",
    "paymentCanceled": "Du hast deine Zahlung abgebrochen. Wenn du möchtest, kannst du versuchen erneut zu bezahlen.",
    "paymentFailed": "Deine Zahlung ist fehlgeschlagen. Wenn du möchtest, kannst du versuchen erneut zu bezahlen.",
    "invalid_order_state": "Deine Bestellung ist abgelaufen oder bereits bezahlt. Falls welche verfügbar sind, kannst du erneut Tickets bestellen.",
    "orderNotFound": "Die angeforderte Bestellung ist nicht (mehr) gültig. Falls vorhanden, kannst du erneut Ticket bestellen.",
    "user_cannot_share": "Dieses Konto hat keine Berechtigung, Tickets zu teilen.",
    "already_in_group": "Du bist bereits Mitglied einer anderen Gruppe für dieses Event. Versuche zuerst die andere Gruppe zu verlassen.",
    "user_cannot_leave": "Du kannst diese Gruppe nicht verlassen, weil du diese Gruppe gestartet hast.",
    "group_is_full": "Du kannst dieser Gruppe nicht beitreten, da sie bereits voll ist",
    "expired_code": "Diese Einladung ist abgelaufen. Frage deine Freunde nach einer neuen Einladung.",
    "invalid_code": "Dieser Einladungscode ist ungültig.",
    "system_unavailable": "Unser Dienst ist derzeit aufgrund technischer Probleme vorübergehend nicht verfügbar. Wir arbeiten derzeit an einer Lösung. Wir entschuldigen uns für die Unannehmlichkeiten.",
    "payment": {
      "canceledOrFailed": "Deine Zahlung wurde vom Aussteller storniert oder abgelehnt. Deine Bestellung ist noch gültig. Du kannst es gerne erneut versuchen.",
      "genericError": "Bei dem Zahlungsdienstleister ist ein Fehler aufgetreten. Wenn das Problem weiterhin besteht, sind Zahlungen im Moment möglicherweise nicht möglich. Bitte versuche es später noch einmal."
    },
    "reservationInvitation": {
      "invalid_state": "I am sorry, there is no valid invitation associated with this link!",
      "claimed": "This invitation has already been claimed!",
      "expired": "This invitation is no longer valid!",
      "unkown_error": "Something went wrong! Please try again later."
    }
  },
  "notFound": {
    "head": {
      "title": " @:global.head.title",
      "complement": "Seite nicht gefunden"
    },
    "title": "Diese Seite kann nicht gefunden werden",
    "description": "Suchst du vielleicht nach einem Event, dessen (Vor-)Verkauf noch nicht begonnen hat oder noch nicht bekannt ist? Dann versuche es bitte später nochmal! Möchtest du mehr über @:brand erfahren. ?",
    "action": "Gehe zu @:brand.homageUrlShort"
  },
  "appstore": {
    "footer": "Installiere die @:brand.name App auf deinem Smartphone. Der einfachste Weg, um reinzukommen!",
    "action": "App installieren",
    "install": {
      "title": "Installiere die @:brand.name mobile App",
      "description": "Wähle den Typ deines Handys, um zum App Store fortzufahren:",
      "forAndroid": "Installiere die @:appstore.android.name-App",
      "forIos": "Installiere die @:appstore.ios.name-App",
      "alsoAndroid": "Auch verfügbar für @:appstore.android.name",
      "alsoIos": "Auch verfügbar für @:appstore.ios.name"
    },
    "android": {
      "name": "Android",
      "store": "Google Play Store",
      "description": "Entdecken auf @:appstore.android.name",
      "url": "@:brand.androidAppstoreUrl"
    },
    "ios": {
      "name": "iPhone (iOS)",
      "store": "Apple App Store",
      "description": "Download in der @:appstore.ios.name",
      "url": "@:brand.appleAppstoreUrl"
    }
  },
  "queue": {
    "start": {
      "queue": {
        "header": {
          "title": "Warteschlange für das Event betreten",
          "description": "Deine Tickets werden mit deiner Handynummer verknüpft: Überprüfe bitte deine Angaben!"
        },
        "actionEnter": "Warteschlange betreten"
      },
      "preQueue": {
        "header": {
          "title": "Verkauf beginnt am {day} {month} um {time}",
          "description": "Schließe dich der Vor-Warteschlange an und du erhältst automatisch eine zufällige Position für den Ticketverkauf um {time}."
        },
        "actionEnter": "Vor-Warteschlange betreten"
      },
      "form": {
        "phonePlaceholder": "Deine Handynummer",
        "emailPlaceholder": "Deine E-Mail-Adresse",
        "focusPhonePlaceholder": "+49123456789",
        "focusEmailPlaceholder": "emailadresse{'@'}gmail.com"
      },
      "privacy": {
        "description": "Durch Eingabe deiner Daten betrittst du die Warteschlange und du stimmst {0} zu.",
        "name": "unsere Datenschutzerklärung"
      }
    },
    "allEventsPast": "Dieses Event hat stattgefunden und Tickets sind nicht mehr verfügbar.",
    "countdown": {
      "positionInfoZero": "Du bist fast dran, warte ...",
      "positionInfoOne": "Es ist nur eine {numberInFront} Person vor Ihnen.",
      "positionInfoMultiple": "Es sind {numberInFront} Personen vor dir.",
      "calculatePosition": "Wir berechnen deine Position",
      "approx": "ca.",
      "minutes": "Minuten verbleibend| Minute verbleibend| Minuten verbleibend",
      "hour": "Mehr als 1 Stunde...",
      "soldout": "keine Chance (ausverkauft)",
      "calculateChance": "Du bist schnell hier! Wir berechnen die Schnelligkeit des Verkaufs und zeigen dir hier deine Chancen in einer Minute.",
      "single": "Du hast die {chance}, dass Tickets für dein Event verfügbar sein werden, sobald du an der Reihe bist.",
      "groupSingle": "Du hast {chance}, dass Tickets für dein Event verfügbar sein werden, sobald du an der Reihe bist",
      "groupMultiple": "Du hast {chance}, dass es Tickets für mindestens eines deiner {count} Events geben wird, wenn du an der Reihe bist.",
      "groupEmtpy": "Gib deine Lieblingsevents an und wähle eines aus, wenn du an der Reihe bist.",
      "selectFav": "Favoriten auswählen",
      "changeFav": "Favoriten anpassen"
    },
    "eventList": {
      "otherData": "Alle Events",
      "soldout": "Ausverkauft",
      "allEvents": "Alle Events anzeigen",
      "favoriteEvents": "Deine Favoriten",
      "search": "Nach Standort filtern",
      "showsIn": "Keine Events in | 1 Event in | {count} Events in",
      "other": "Andere Orte",
      "modal": {
        "title": "Wähle deine Lieblingsevents",
        "subtitle": "Wenn du an der Reihe bist, werden deine Lieblingsevents zuerst in der Liste angezeigt, von der du wählen kannst. Einfach!"
      },
      "few_available": "fast ausverkauft",
      "no_available": "ausverkauft",
      "tickets_in_carts": "ausverkauft"
    },
    "preQueue": {
      "starting": {
        "title": "Position zuweisen...",
        "description": "Du erhältst eine zufällige Position in der Warteschlange, so dass jeder eine faire Chance hat, Tickets zu kaufen."
      },
      "waiting": {
        "title": "Count-Down bis {time}",
        "description": "Wenn die Warteschlange geöffnet wird, erhältst du eine zufällige Position. Um Tickets zu kaufen musst du warten bis du an der Reihe bist."
      },
      "footer": {
        "noNumber": "Du hast dich mit deiner Handynummer registriert. Wenn sie nicht korrekt ist, kannst du keine Tickets kaufen: {link} zum erneuten Betreten.",
        "description": "Du hast dich mit der Handynummer {number} registriert. Wenn diese Nummer nicht korrekt ist, kannst du keine Tickets kaufen: {link} zum erneuten Einstieg.",
        "link": "Klick hier"
      }
    },
    "paused": {
      "queuePaused": "Warteschlange pausiert",
      "details": "Aufgrund der extremen Nachfrage lassen wir derzeit niemanden in die Warteschlange. Einen Moment bitte..."
    },
    "turn": {
      "single": {
        "title": "Du bist an der Reihe! ",
        "description": "Du kannst jetzt deine Tickets bestellen",
        "order": "Bestellen"
      },
      "group": {
        "title": "Du bist an der Reihe! ",
        "description": "Wähle ein Event aus. Du kannst immer wieder hierher zurückkehren, um ein anderes Event auszuwählen."
      }
    },
    "open": {
      "title": "Event auswählen"
    },
    "notStarted": {
      "description": "Der Ticketverkauf hat noch nicht begonnen. Er wird am {date} um {time} beginnen - komm dann bitte wieder.",
      "action": "Event anzeigen",
      "support": "Weitere Informationen über den Bestellvorgang findest du unter {0}.",
      "supportLink": "Hilfe-Seite"
    },
    "soldout": {
      "title": "Dieses Event ist derzeit ausverkauft",
      "details": "Schade, alle verfügbaren Tickets wurden verkauft. Es besteht die Chance, dass es wieder Tickets zum Verkauf geben wird, also schau doch später nochmal rein!"
    },
    "access": {
      "description": "Dieser Shop ist nur über die vom Veranstalter zur Verfügung gestellten Zugangslinks zugänglich. Sobald du den Zugang zu einem Shop beantragt hast, kannst du immer wieder dorthin zurückkehren.",
      "checkAccess": "Zugang prüfen"
    }
  },
  "shop": {
    "global": {
      "tickets": "Tickets | Ticket | Tickets",
      "total": "Gesamt",
      "free": "Kostenlos",
      "serviceCosts": "Servicekosten",
      "serviceCostsLong": "Inklusive @:shop.global.serviceCosts",
      "serviceCostsShort": "Inkl. @:shop.global.serviceCosts",
      "buyerFee": "Inklusive {fee} Gebühr",
      "floorplan": "Sitzplan",
      "back": "Zurück zum Shop"
    },
    "tabs": {
      "info": "Info",
      "seated": "& Sitzplan"
    },
    "eventInfo": {
      "openDoors": "Öffnungszeit",
      "startTime": "Start",
      "endTime": "Ende",
      "openMap": "Auf Karte öffnen",
      "timezone": "Hinweis: Zeitzone ist {timezone}"
    },
    "floorplan": {
      "description": "Wähle den gewünschten Abschnitt auf dem Sitzplan aus, um Tickets zu kaufen.",
      "action": "Bestellung mit Sitzplan",
      "list": "Oder wähle deinen Rang und Abschnitt aus der Liste unten."
    },
    "resale": {
      "actionBack": "Zurück zum Hauptshop",
      "resaleLink": "Ticketmarkt anzeigen",
      "available": "{count} verfügbar",
      "originalPrice": "Originalpreis {price}",
      "showMore": "Mehr Tickets anzeigen",
      "checkMore": "Weitere Tickets suchen",
      "refreshMessage": "Es scheint, dass einige dieser Tickets bereits verkauft wurden und die aktuelle Liste möglicherweise veraltet ist. Möchtest du die Liste aktualisieren?",
      "emptyMessage": "Derzeit bietet niemand Tickets zum Verkauf über unseren Ticketmarkt an. Schau später noch einmal vorbei oder aktualisiere die Liste. Leute können zu jederzeit neue Tickets anbieten!",
      "noOthers": "Derzeit werden die einzigen verfügbaren Tickets von {seller} angeboten. Bitte schau oben nach oder aktualisiere die Liste.",
      "noOthersFallback": "Derzeit sind nur die oben aufgeführten Tickets verfügbar. Du kannst auch aktualisieren.",
      "resellerItems": {
        "manyResellers": "{names} und {others_count} andere",
        "isSelling": " verkauft Tickets",
        "noTickets": "Alle angebotenen Tickets dieser Person, sind entweder bereits verkauft, oder werden momentan von jemandem gekauft.",
        "others": "Von anderen angebotene Tickets:"
      },
      "resaleItem": {
        "price": "Tickets für jeweils {price}| {count} Ticket für {price} | {count} Tickets für jeweils {price}",
        "priceUpsell": "Tickets für jeweils {price}| {count} Ticket für {price} | {count} Tickets für jeweils {price}",
        "seller": "Angeboten bei {name}",
        "justSold": {
          "warning": "Soeben verkauft!",
          "message": "Jemand war leider schneller"
        }
      },
      "resaleHeader": {
        "title": "Tickets in diesem Shop sind ausverkauft!",
        "description": "Allerdings: Jeder mit Tickets kann diese jederzeit auf unserem Ticketmarkt zum Verkauf anbieten!"
      },
      "feesInfo": {
        "title": "Erläuterung der Gebühren für den Wiederverkaufsmarkt",
        "description": "Für jedes Ticket berechnen wir dem Käufer eine Gebühr von {percentage} plus {fixed}",
        "descriptionWithFixedFees": "Es wird eine {percentage} Servicegebühr zuzüglich {fixed} Transaktionskosten pro Ticket erhoben.",
        "buyerFee": "Gebühr für den Wiederverkaufsmarkt",
        "totalPrice": "Preis, den Du bezahlst",
        "footerWithFixedFees": "Für den Verkäufer wird eine {percentage} Servicegebühr zuzüglich {fixed} Transaktionskosten pro Ticket erhoben. Dies ist bereits in deinem Preis enthalten.",
        "footer": "Wir berechnen auch Verkäufern {percentage} - dies ist bereits in Ihrem Preis enthalten."
      }
    },
    "tickets": {
      "soldout": "Ausverkauft",
      "ticketsLeft": "{left} übrig",
      "tutorial": "Brauchst du Hilfe?",
      "upsell": "Extra"
    },
    "footer": {
      "maxTickets": "Maximal {amount} Ticket pro Person | Maximal {amount} Tickets pro Person",
      "userMaxTickets": "Du kannst nicht mehr Tickets bestellen | Du kannst {amount} weitere Tickets bestellen | Du kannst {amount} weitere Tickets bestellen",
      "maxTicketsLeft": "von max. {amount}",
      "actionOrder": "Bestellen",
      "actionNext": "Nächste"
    },
    "state": {
      "past": {
        "title": "Dieses Event hat bereits stattgefunden.",
        "description": "Es sind keine Tickets mehr verfügbar."
      },
      "soldoutWithoutResale": {
        "title": "Alle Tickets sind (aktuell) ausverkauft",
        "description": "Bald könnten wieder mehr Karten verfügbar sein."
      }
    },
    "datepicker": {
      "toggle": "Wähle einen anderen Tag",
      "legend": {
        "title": "Wähle einen Tag aus, um deine Tickets zu kaufen",
        "soldout": "Diese Tage sind derzeit ausverkauft",
        "hasEvents": "An diesem Tag sind noch Tickets verfügbar"
      },
      "shopList": {
        "shopCount": "Es gibt {count} Event(s) an diesem Tag",
        "description": "Wähle ein Event aus, um deine Tickets zu kaufen"
      },
      "noEvents": {
        "titleMonth": "Keine Events in diesem Monat",
        "descriptionMonth": "In diesem Monat gibt es keine Events. Nutze den Kalender, um einen weiteren Monat in der Zukunft auszuwählen und die gesamte Agenda zu durchsuchen.",
        "titleDay": "Keine Events an diesem Tag",
        "descriptionDay": "An diesem Tag gibt es keine Events. Nutze den Kalender, um einen anderen Tag in der Zukunft auszuwählen und die gesamte Agenda zu durchsuchen."
      }
    },
    "loadingMessage": {
      "wait": "Bitte warte einen Moment",
      "contacting": "Kontaktaufnahme mit dem Geschäft",
      "browser": "Den Browser nicht schließen",
      "fetching": "Verfügbare Tickets werden abgerufen",
      "starting": "Shop wird gestartet",
      "populating": "Shop wird befüllt",
      "opening": "Shop wird geöffnet"
    },
    "wallet-connect": {
      "notification": {
        "title": "Check your eligibility to buy tickets",
        "description": {
          "main": "This ticket shop is accessible if you hold the required <a target=\"_blank\" href=\"https://support.guts.tickets/hc/en-001/articles/4410619860242-What-is-an-Event-Collectible\">digital collectible(s)</a> in your wallet.",
          "login": "Please login with your mobile number and verify ownership of your wallet that holds the collectible(s), to check your eligibility.",
          "verifyAccess": "Please verify ownership of your wallet that holds the collectible(s), to check your eligibility."
        },
        "button": {
          "login": "LOG IN",
          "verify": "VERIFY ACCESS"
        }
      },
      "connect": {
        "title": "Connect your wallet first!",
        "description": "To access the shop, you need to hold a collectible from one of the following collections:",
        "button": "Connect wallet"
      },
      "searching": {
        "title": "Checking Collectibles in your wallet..."
      },
      "notFound": {
        "title": "We didn't find the required Collectibles in your wallet",
        "description": "If you think this is an error, please try connecting to another wallet to re-check your eligibility",
        "button": "Connect another wallet"
      },
      "sign": {
        "title": "Confirm you’re the owner of the Wallet",
        "description": "We need to verify ownership of the connected wallet in order to give you access to this shop",
        "button": "Sign via wallet"
      },
      "success": {
        "title": "You have access to the Shop!",
        "description": "We have successfully verified ownership of the provided wallet that holds the required collectible(s) to access the shop",
        "button": "Go to Shop"
      },
      "connectedWallet": "Connected wallet",
      "previousWallet": "Previously connected wallet",
      "disconnect": "Disconnect",
      "searched": "Searched Collections",
      "results": "Result",
      "status": {
        "not-found": "Not found",
        "found": "Found",
        "no-collections": "Error: no collections to check were found."
      }
    }
  },
  "account": {
    "head": {
      "title": "Konto"
    },
    "login": {
      "title": "Überprüfe deine Handynummer",
      "description": "Gib deine Handynummer inklusive Landesvorwahl ein. Du erhältst einen Zugangscode per SMS.",
      "phonePlaceholder": "Deine Handynummer",
      "focusPhonePlaceholder": "+49123456789",
      "actionVerify": "Überprüfen",
      "searchCountryCode": "Suche nach deiner Landesvorwahl",
      "selectCountry": "Wähle dein Land",
      "mobileInputDescription": "Muss deinen Ländercode enthalten, zum Beispiel +1"
    },
    "callUser": {
      "title": "Code nicht erhalten?",
      "description": "Unser Roboter kann dich anrufen und dir deinen Verifizierungs-Code laut vorlesen.",
      "incorrectNumber": "Meine Telefonnummer ist nicht korrekt",
      "action": "Anrufen auf {phone}",
      "calling": "Du wirst jetzt angerufen"
    },
    "verify": {
      "title": "Überprüfe deine Handynummer",
      "description": "Ein Code wurde an {number} gesendet. Gib den vierstelligen Code ein.",
      "triesLeft": "Dein Code ist nicht korrekt. Du kannst es noch {times} versuchen.",
      "time": "{tries} mal | {tries} mal",
      "requestCodeCountdown": "Erneutes Senden ist in {countdown} Sekunden verfügbar."
    },
    "captcha": {
      "notice": "Diese Funktion ist durch reCAPTCHA geschützt und es gelten die Google {privacy} und {tos}.",
      "privacy": "Datenschutzrichtlinie",
      "tos": "Geschäftsbedingungen",
      "title": "Bitte verifiziere, dass du ein Mensch bist",
      "description": "Kreuze das Feld unten an, um zu beweisen, dass du kein Bot bist. Wir fragen gelegentlich danach, um Spammer und Betrüger zu verhindern."
    }
  },
  "profile": {
    "head": {
      "title": "Mein Profil"
    },
    "title": "Mein Profil",
    "description": "Dein Profil könnte bei einem Event überprüft werden. Stelle sicher, dass die Informationen korrekt sind.",
    "mandatory": "Die mit * gekennzeichneten Felder sind Pflichtfelder.",
    "additonalInfoTitle": "Zusätzliche Informationen",
    "additonalInfoDescription": "Der Veranstalter bittet um die folgenden zusätzlichen Informationen.",
    "terms": "Ich stimme den <a href=@:brand.termsUrl target=\"_blank\">AGB</a> von @:brand.nameShort <small>(obligatorisch)</small> zu",
    "optIn": "@:brand.nameShort darf meine Daten an Organisation weitergeben, dessen Tickets ich kaufe, und mich über Neuigkeiten und bevorstehende Termin informieren, wie in <a href=@:brand.privacyPolicyUrl target=\"_blank\">den Datenschutzrichtlinien</a> angegeben.",
    "firstName": "Vorname *",
    "lastName": "Nachname *",
    "email": "E-Mail-Adresse *",
    "gender": "Geschlecht",
    "male": "Männlich",
    "female": "Weiblich",
    "other": "Andere",
    "birthdate": "Geburtsdatum",
    "dateProperties": {
      "day": "Tag",
      "month": "Monat",
      "year": "Jahr"
    },
    "country": "Land",
    "state": "Bundesland",
    "zipcode": "PLZ",
    "address": "Straßenname & Hausnummer",
    "city": "Stadt",
    "nft_address": {
      "wallet_address": "Wallet address",
      "title": "Stelle die Adresse deines NFT-Wallets ein",
      "description_off": "Receive event collectibles on your own existing Web3 wallet. By default a Web3 wallet will be created for you.",
      "description_on": "Receive event collectibles on your own Web3 wallet by connecting it once below. Collectibles are distributed on the Polygon blockchain.",
      "description_connected": "Your event collectibles will be sent to the wallet connected below on the Polygon blockchain. Reset to switch to a different wallet.",
      "help": "Du weißt nicht, was das ist? <a href=\"{url}\" target=\"_blank\">Mehr erfahren</a>",
      "overview": "Du erhältst deine digitalen Sammel-NFTs über die folgende Ethereum-/Polygon-Wallet-Adresse",
      "reset": "Zurücksetzen",
      "edit": "Edit",
      "confirmation": {
        "title": "Bist du sicher?",
        "description": "So kannst du eine andere Wallet-Adresse für zukünftige Ansprüche auf digitale Sammlerstücke festlegen oder sie einfach leer lassen, um keine Ansprüche mehr zu stellen."
      }
    },
    "actionSave": "Speichern"
  },
  "order": {
    "head": {
      "title": "Deine Bestellung"
    },
    "overview": {
      "credits": {
        "applied": "Verwendetes Guthaben",
        "optIn": "Mein Guthaben zur Bezahlung dieser Bestellung verwenden",
        "appliedDescription": "Sie haben {amount} Guthaben zu dieser Bestellung hinzugefügt.",
        "availableDescription": "Sie haben {amount} verbleibende Credits auf Ihrem Konto. Diese Credits sind für {org} Veranstaltungen gültig und verfallen am {expirationDate}."
      },
      "organisationName": "der Veranstalter",
      "termsOptIn": {
        "url": "Ich stimme den <a href=\"{url}\" target=\"_blank\">AGB</a> von {name} zu <small>(obligatorisch)</small>",
        "custom": "{label} – <a target=\"_blank\" href=\"{url}\">Geschäftsbedingungen</a> <small>(obligatorisch)</small>",
        "text": "<span>Ich erkläre mich mit den Bedingungen von {name} einverstanden <small>(obligatorisch)</small></span><p>{text}</p>"
      },
      "privacyOptIn": {
        "url": "Ich möchte über Events und Werbeaktionen informiert werden und stimme der <a href=\"{url}\" target=\"_blank\">-Datenschutzerklärung</a> von {name} zu",
        "custom": "{label} – <a target=\"_blank\" href=\"{url}\">Datenschutzrichtlinie</a>",
        "text": "<span>Ich möchte über Events und Aktionen informiert werden und erkläre mich mit den Datenschutzbestimmungen von {name} einverstanden</span><p>{text}</p>"
      },
      "splitMarketTerms": "Ich akzeptiere die <a href=\"{url}\" target=\"_blank\">Geschäftsbedingungen für den Wiederverkauf</a> von GET International",
      "timeLeft": "Du hast jetzt 15 Minuten Zeit, um deine Bestellung abzuschließen",
      "actionFree": "Bestätigen",
      "actionPaid": "Zahle"
    },
    "giftcard": {
      "title": "Ich habe eine Geschenkkarte oder einen Gutscheincode",
      "description": "Gib deinen Code unten ein, um ihn einzulösen",
      "action": "Einlösen",
      "placeholder": "Zum Beispiel: \"321BDFK874\"",
      "label": "Geschenkkarte oder Gutscheincode",
      "modal": {
        "label": "Gültige(r) Geschenkkarte/Gutscheincode",
        "title": "Wert",
        "description": "So wird es ablaufen",
        "description-one": "Deinem Konto wird {amount} gutgeschrieben",
        "description-two-a": "Du kannst diese Gutschrift verwenden für",
        "description-two-b": "alle Events von {org}",
        "description-three": "Es wird ablaufen am",
        "action": "Einlösen",
        "cancel": "Jetzt nicht"
      },
      "error": {
        "empty": "Bitte gib eine gültige Geschenkkarte oder einen Gutscheincode ein",
        "invalid": "Dieser Code ist ungültig",
        "expired": "Dieser Code ist abgelaufen",
        "used": "Dieser Code wurde bereits verwendet",
        "wrong_organizer": "Dieser Code kann nicht für {org} verwendet werden",
        "generic": "Etwas ist schief gelaufen, der Code kann nicht eingelöst werden"
      }
    },
    "payment": {
      "ideal": "Wähle deine iDeal Bank",
      "other": "Wähle deine Zahlungsart",
      "noPaymentOptions": "Zur Zeit gibt es keine Zahlungsmethoden. Versuche diese Seite zu aktualisieren. Wenn das Problem weiterhin besteht, <a href=\"@:brand.supportUrl\" target=\"_blank\">Kontaktiere uns bitte </a>."
    },
    "paymentLoader": {
      "redirecting": "Du wirst zu {method} weitergeleitet...",
      "error": "{method} scheint Probleme zu haben.\nVersuchen es erneut oder wähle eine andere Zahlungsmethode..."
    },
    "status": {
      "pending": {
        "title": "Deine Bestellung wird verarbeitet..."
      },
      "paid": {
        "title": "Bestellung erfolgreich!",
        "description": "Eine Bestätigung wurde an {email} gesendet - überprüfe deinen Spam-Ordner.",
        "descriptionAnonymous": "An order confirmation has been sent to you - if it's not there, be sure to check your spam. ",
        "actionTickets": "Gehe zu Online-Tickets"
      },
      "timeout": {
        "title": "Bitte warten...",
        "description": "Leider konnten wir deine Zahlung noch nicht bestätigen. Sobald wir das tun, erhältst du eine Bestätigung unter {email}."
      },
      "error": {
        "title": "Bestellung fehlgeschlagen...",
        "description": "Beim Abschluss deiner Bestellung ist etwas schiefgelaufen. Du kannst versuchen Tickets erneut zu bestellen.",
        "actionBack": "Zurück zum Event"
      }
    },
    "app": {
      "title": "Tickets von @:brand.nameShort funktionieren auf deinem Smartphone, also hol dir die App!",
      "description": "Vorteile der App:",
      "imageAlt": "@:brand.name Wallet App für iPhone & Android",
      "featureList": [
        "Mit den digitalen Tickets in der App kannst du die Event betreten.",
        "Tickets ganz einfach verkaufen, wenn du es nicht schaffst teilzunehmen.",
        "Tickets verschenken oder mit Freunden teilen ."
      ]
    }
  },
  "tickets": {
    "index": {
      "head": {
        "title": "Meine Events"
      },
      "title": "Meine anstehenden Events",
      "noEventsTitle": "Keine anstehenden Event",
      "noEventsDescription": "Du hast keine Tickets für kommende Events."
    },
    "details": {
      "head": {
        "title": "Tickets"
      },
      "actionBack": "Meine Events",
      "inactive": {
        "title": "Dein QR-Code ist gesichert",
        "description": "Er wird automatisch am {unlockDate} angezeigt."
      },
      "active": {
        "title": "Lass diesen QR-Code beim Event einscannen",
        "description": "Dieser Code funktioniert für alle Tickets und Freunde."
      },
      "notScannable": {
        "title": "Keine Tickets zum Einchecken",
        "description": "Alle deine Tickets wurden bereits eingecheckt, verkauft oder zurückgegeben."
      },
      "noCopies": "Kopien oder Ausdrucke funktionieren nicht",
      "seating": {
        "seatsAppear": "Platznummern werden am Tag vor dem Event zugewiesen.",
        "entrance": "Eingang",
        "section": "Abschnitt",
        "row": "Reihe",
        "seats": "Sitzplatz"
      },
      "group": {
        "info": "Gruppe von {activeUsers}/{groupSize}",
        "leave": "Verlassen",
        "confirmLeave": "Bist du sicher, dass du diese Gruppe verlassen möchtest?"
      },
      "livestream": {
        "watchStream": "Ansehen",
        "upsell": {
          "shopLink": "Zum Shop",
          "warning": "Hinweis: Du hast keine Tickets gekauft",
          "description": "Du hast nur Extras gekauft. Wenn du auch vorhattest, Tickets zu kaufen, kannst du das immer noch tun."
        },
        "inactive": {
          "title": "Zugriff auf Livestream",
          "description": "Der Livestream wird direkt hier am {unlockDate} erreichbar sein."
        },
        "unauthorized": {
          "title": "Kein Zugriff auf Livestream",
          "description": "Du hast den exklusiven Zugang zu diesem Livestream nicht gekauft"
        },
        "active": {
          "title": "Livestream hat begonnen!",
          "description": "Du hast exklusiven Zugang zu diesem Livestream."
        }
      },
      "footer": {
        "title": "Möchtest du deine Tickets teilen oder verkaufen?",
        "description": "Installiere die mobile App für mehr Funktionen."
      }
    }
  },
  "invite": {
    "title": "Einladung von",
    "shareTickets": "{ticketAmount} Ticket für alle geteilt | {ticketAmount} Tickets für alle geteilt",
    "group": "{personAmount} Personen bereits in der Gruppe | {personAmount} Personen sind bereits in der Gruppe",
    "confirmAccept": "Bist du sicher, dass du dieser Gruppe beitreten möchtest?",
    "confirmDecline": "Bist du sicher, dass du dieser Gruppe nicht beitreten möchtest?",
    "warning": "<strong>Akzeptiere nur, wenn du {inviterName}kennst und vertraust !</strong> Jeder in dieser Gruppe hat genau die gleichen Rechte - einschließlich des Rechts, andere einzuladen und alle Tickets für diese Event zu verkaufen inklusive deine!",
    "invite": "Einladung von",
    "accept": "akzeptieren",
    "decline": "ablehnen"
  },
  "pendingPayment": {
    "title": "Warnung: Ausstehende Zahlung",
    "pay": "Zahlen",
    "wait": "Warten",
    "existingPayment": "Du hast bereits eine Zahlung für diese Bestellung begonnen.",
    "confirmationEmail": "Du wirst in Kürze eine Bestätigungs-E-Mail erhalten, wenn du diese Zahlung abgeschlossen hast.",
    "confirm": "Möchtest du eine neue Zahlung beginnen oder warten?"
  },
  "reservationInvitation": {
    "claimAction": "Claim tickets",
    "status": {
      "title": "Great, the tickets are yours!"
    }
  }
}
