{
  "global": {
    "language": "Italiano",
    "actionClose": "Chiudi",
    "actionBack": "Indietro",
    "actionCancel": "Annulla",
    "optional": "opzionale",
    "head": {
      "title": "@:brand.name",
      "description": "Comprare e rivendere biglietti in sicurezza? Con @:brand.nameShort sconfiggi i bagarini e i prezzi esrobitanti, grazie alla tecnologia Blockchain. Unisciti a noi con qualsiasi evento! Dai concerti a teatro agli eventi sportivi: con @:brand.nameShort comprare e rivendere biglietti è facile, trasparente e sicuro",
      "twitter": "@:brand.twitter",
      "event": {
        "title": "Biglietti per {title}",
        "open": "Ordina il tuo biglietto per {title}, {date} a {location} su @:brand.name",
        "queue": "Ordina il tuo biglietto per {title} su @:brand.name",
        "resale": "Check all tickets on our Ticket Market for {title}, {date} in {location} on @:brand.name",
        "reseller": "My tickets are up for sale for {title}, {date} in {location} on @:brand.name. Get them while you can!"
      }
    },
    "fullscreen": "Visualizza a schermo intero per un'esperienza migliore",
    "cookiesConsent": {
      "description": "Facciamo uso di cookies per migliorare la tua esperienza.",
      "actionAccept": "Accetta cookies",
      "actionMore": {
        "name": "Leggi altro"
      }
    },
    "logout": {
      "logoutText": "Disconnetti",
      "logoutConfirmText": "Sei sicuro? Quando accederai nuovamente riceverai un nuovo codice di accesso via messaggio."
    },
    "navigation": {
      "account": "Account",
      "eventList": "Eventi",
      "myTickets": "I miei biglietti",
      "login": "Accedi",
      "profile": "Profilo"
    }
  },
  "brand": {
    "name": "#.name",
    "nameShort": "#.nameShort",
    "twitter": "#.twitter",
    "homepageUrl": "#.homepageUrl",
    "homepageUrlShort": "#.homepageUrlShort",
    "supportUrl": "#.supportUrl",
    "livestreamSupportUrl": "#.livestreamSupportUrl",
    "privacyPolicyUrl": "#.privacyPolicyUrl",
    "termsUrl": "#.termsUrl",
    "androidAppstoreUrl": "#.androidAppstoreUrl",
    "appleAppstoreUrl": "#.appleAppstoreUrl"
  },
  "validation": {
    "errors": {
      "required": "Questo campo non può essere vuoto",
      "email": "Questo indirizzo mail non è valido",
      "phoneNumber": "Questo numero di telefono non è valido",
      "rateLimit": {
        "message": "Too many verification attempts.",
        "minutes": "Please try again in 2 minutes.",
        "seconds": "Please try again in {seconds} seconds."
      },
      "globalBlock": {
        "message": "Too many verification attempts.",
        "minutes": "Please try again in a few minutes.",
        "seconds": "Please try again in {seconds} seconds."
      },
      "birthdate": "Questa data di nascita non è valida"
    }
  },
  "messages": {
    "saveProfile": "Profilo salvato con successo!",
    "seated": "Hai ordinato i biglietti, e ora? Riceverai il numero del tuo posto a sedere almeno 24 ore prima dell'evento, via mail, messaggio o direttamente in questa app!",
    "seatsAssigned": "Note: If you are buying seated tickets from different people, we can't guarantee that your seats will be contiguous."
  },
  "errors": {
    "default": "Oops! Qualcosa è andato storto...<br/>Puoi <a onClick='window.location.reload(false)'>ricaricare la pagina</a> e provare nuovamente.",
    "notStarted": "La vendita per questo evento non è ancora iniziata. Assicurati che il tuo telefono/computer abbia data e ora impostate correttamente.",
    "invalidURL": "Errore: Questo codice di accesso non è valido o è già stato usato.",
    "saveProfile": "Errore: Impossibile salvare le informazioni di profilo.",
    "exceeding_limits": "Hai superato il numero massimo di biglietti acquistabili consentito.",
    "token_different_user": "If you logged in with a different mobile number than the one provided when entering the queue, please use the refresh button to re-enter with the new correct number.",
    "token_expired": "Your session for this shop has expired, please use the refresh button to re-enter",
    "paymentCanceled": "Hai annullato il pagamento. Se lo desideri, puoi riprovare l'acquisto.",
    "paymentFailed": "Per qualche motivo il tuo pagamento non è andato a buon fine. Ti preghiamo di riprovare.",
    "invalid_order_state": "Il tuo ordine è scaduto oppure hai già pagato. Puoi fare un nuovo ordine se altri biglietti sono disponibili.",
    "orderNotFound": "L'ordine richiesto non è più valido. Puoi fare un nuovo ordine se altri biglietti sono disponibili.",
    "user_cannot_share": "Questo account non ha i permessi per condividere i biglietti.",
    "already_in_group": "Sei già membro di un altro gruppo per questo evento specifico. Prova come prima cosa a lasciare l'altro gruppo.",
    "user_cannot_leave": "Non puoi lasciare questo gruppo perché l'hai creato tu.",
    "group_is_full": "Non ti puoi unire a questo gruppo perché ha già raggiunto il numero massimo di partecipanti",
    "expired_code": "Questo invito è scaduto. Chiedi un nuovo invito al tuo amico/a.",
    "invalid_code": "Questo codice di invito non è valido.",
    "system_unavailable": "Our service is temporarily unavailable at the moment due to technical issues. We are currently working on a solution. We apologize for the inconvenience.",
    "payment": {
      "canceledOrFailed": "Your payment was canceled or refused by the issuer. Your order is still valid. Feel free to retry.",
      "genericError": "Something went wrong with the payment service provider. If the issue persists, payments may not be possible at this moment. Please try again later."
    },
    "reservationInvitation": {
      "invalid_state": "I am sorry, there is no valid invitation associated with this link!",
      "claimed": "This invitation has already been claimed!",
      "expired": "This invitation is no longer valid!",
      "unkown_error": "Something went wrong! Please try again later."
    }
  },
  "notFound": {
    "head": {
      "title": " @:global.head.title",
      "complement": "Pagina non trovata"
    },
    "title": "Questa pagina non è stata trovata",
    "description": "Forse stai cercando un evento per cui la (pre)vendita non è ancora cominciata? In questo caso ti chiediamo di riprovare più tardi! Vuoi sapere di più su @:brand.name?",
    "action": "Go to @:brand.homepageUrlShort"
  },
  "appstore": {
    "footer": "Installa l'app @:brand.name sul tuo telefono!",
    "action": "Installa app",
    "install": {
      "title": "Installa l'app @:brand.name",
      "description": "Scegli il tipo di dispositivo che usi per proseguire allo store:",
      "forAndroid": "Installa @:appstore.android.name app",
      "forIos": "Installa @:appstore.ios.name app",
      "alsoAndroid": "Disponibile anche per @:appstore.android.name",
      "alsoIos": "Disponibile anche per @:appstore.ios.name"
    },
    "android": {
      "name": "Android",
      "store": "Google Play Store",
      "description": "Scarica da @:appstore.android.name",
      "url": "@:brand.androidAppstoreUrl"
    },
    "ios": {
      "name": "iPhone (iOS)",
      "store": "Apple App Store",
      "description": "Scarica da @:appstore.ios.name",
      "url": "@:brand.appleAppstoreUrl"
    }
  },
  "queue": {
    "start": {
      "queue": {
        "header": {
          "title": "Accedi alla coda dell'evento",
          "description": "Il tuo biglietto verrà collegato al tuo numero di telefono: assicurati che i dati forniti siano corretti!"
        },
        "actionEnter": "Mettiti in coda"
      },
      "preQueue": {
        "header": {
          "title": "Sale starts on {day} {month} at {time}",
          "description": "Join the pre-queue and you will automatically receive a random position for the ticket sale at {time}."
        },
        "actionEnter": "Enter pre-queue"
      },
      "form": {
        "phonePlaceholder": "Il tuo numero di telefono",
        "emailPlaceholder": "Il tuo indirizzo mail",
        "focusPhonePlaceholder": "+31612345678",
        "focusEmailPlaceholder": "indirizzomail{'@'}gmail.com"
      },
      "privacy": {
        "description": "Immettendo i tuoi dati, ti metti in coda ed aderisci a {0}.",
        "name": "la nostra politica sulla privacy"
      }
    },
    "allEventsPast": "Questo evento è scaduto e i biglietti non sono più disponibili.",
    "countdown": {
      "positionInfoZero": "It's almost your turn, hold on...",
      "positionInfoOne": "There is only {numberInFront} person in front of you.",
      "positionInfoMultiple": "There are currently {numberInFront} people in front of you.",
      "calculatePosition": "Calcola la tua posizione",
      "approx": "circa",
      "minutes": "minuti rimasti | minuti rimasti | minuti rimasti",
      "hour": "Più di 1 ora...",
      "soldout": "nessuna chance (sold out)",
      "calculateChance": "Sei arrivato qui velocemente! Stiamo calcolando quanto procede veloce la vendita e ti mostreremo qui le tue possibilità in pochi minuti.",
      "single": "Hai {chance} che ci saranno biglietti disponibili quando sarà il tuo turno.",
      "groupSingle": "Hai {chance} che ci saranno biglietti disponibili per il tuo evento preferito, quando sarà il tuo turno",
      "groupMultiple": "Hai {chance} che ci saranno biglietti disponibili per almeno uno dei tuoi {count} eventi preferiti, quando sarà il tuo turno.",
      "groupEmtpy": "Seleziona il tuo evento preferito e scegline uno facilmente quando è il tuo turno.",
      "selectFav": "Seleziona favoriti",
      "changeFav": "Modifica favoriti"
    },
    "eventList": {
      "otherData": "Tutti gli eventi",
      "soldout": "Sold out",
      "allEvents": "Visualizza tutti gli eventi",
      "favoriteEvents": "I tuoi preferiti",
      "search": "Filtra su luogo",
      "showsIn": "No eventi a | 1 evento a | {count} eventi a",
      "other": "Altri luoghi",
      "modal": {
        "title": "Seleziona il tuo evento(i) preferito(i)",
        "subtitle": "Quando sarà il tuo turno, il tuo evento preferito verrà mostrato come primo nella lista da cui puoi scegliere. Facile!"
      },
      "few_available": "Quasi sold out",
      "no_available": "sold out",
      "tickets_in_carts": "sold out"
    },
    "preQueue": {
      "starting": {
        "title": "Assigning position...",
        "description": "You'll get a random position in the queue, so everyone has a fair chance to buy tickets. Hold on."
      },
      "waiting": {
        "title": "Counting down to {time}",
        "description": "When the sale queue opens you'll get a random position and you have to wait until it's your turn to buy tickets."
      },
      "footer": {
        "noNumber": "You registered with your mobile number. If it is not correct you'll' not be able to buy tickets: {link} to re-enter.",
        "description": "You registered with mobile number {number}. If this number is not correct you'll not be able to buy tickets: {link} to re-enter.",
        "link": "Go here"
      }
    },
    "paused": {
      "queuePaused": "Coda in pausa",
      "details": "A causa di una domanda estrema, al momento non stiamo permettendo a nessuno di mettersi in coda. Solo un momento per favore..."
    },
    "turn": {
      "single": {
        "title": "Il tuo turno!",
        "description": "Ora puoi ordinare i tuoi biglietti",
        "order": "Ordina"
      },
      "group": {
        "title": "Il tuo turno!",
        "description": "Seleziona un evento. Puoi sempre tornare indietro qui e sceglierne uno diverso."
      }
    },
    "open": {
      "title": "Scegli un evento"
    },
    "notStarted": {
      "description": "La vendita dei biglietti non è ancora cominciata. Inizierà il {date} alle {time} per favore ritorna quel giorno.",
      "action": "Visualizza eventi",
      "support": "Per favore consulta il nostro {0} per maggiori informazioni in merito al processo di ordinazione.",
      "supportLink": "pagina di supporto"
    },
    "soldout": {
      "title": "Questo evento è attualmente sold out",
      "details": "Peccato, tutti i biglietti disponibili sono stati venduti. C'è una chance che i biglietti vengano rivenduti di nuovo, quindi assicurati di verificare ancora in seguito!"
    },
    "access": {
      "description": "This shop is only accessible using access links provided by the event organizer. Once you have claimed access to a shop, you will always be able to return.",
      "checkAccess": "Check Access"
    }
  },
  "shop": {
    "global": {
      "tickets": "Biglietti | Biglietto | Biglietti",
      "total": "Totale",
      "free": "Gratis",
      "serviceCosts": "Costi di servizio",
      "serviceCostsLong": "Including @:shop.global.serviceCosts",
      "serviceCostsShort": "Incl. @:shop.global.serviceCosts",
      "buyerFee": "Includes {fee} fee",
      "floorplan": "Piantina",
      "back": "Torna al negozio"
    },
    "tabs": {
      "info": "Info",
      "seated": "& posti"
    },
    "eventInfo": {
      "openDoors": "Open",
      "startTime": "Inizio",
      "endTime": "Fine",
      "openMap": "Apri sulla mappa",
      "timezone": "Note: Timezone is {timezone}"
    },
    "floorplan": {
      "description": "Seleziona la sezione desiderata sulla piantina per acquistare facilmente i biglietti.",
      "action": "Ordina utilizzando la piantina",
      "list": "O seleziona la tua fila e sezione dalla lista sottostante."
    },
    "resale": {
      "actionBack": "Torna al negozio principale",
      "resaleLink": "Check Ticket Market",
      "available": "{count} disponibile",
      "originalPrice": "Lowest price {price}",
      "showMore": "Mostra più biglietti",
      "checkMore": "Check for more tickets",
      "refreshMessage": "It seems some of these tickets have already been resold and the current list might be outdated. Would you like to refresh the list?",
      "emptyMessage": "Currently, no one is offering tickets up for sale through our Ticket Market. Check back later, or refresh, because people can offer their tickets at any time!",
      "noOthers": "Currently, the only tickets available are offered by {seller}. Please check above, or refresh.",
      "noOthersFallback": "Currently, the only available tickets are listed above. You can also refresh.",
      "resellerItems": {
        "manyResellers": "{names} and {others_count} others",
        "isSelling": "is selling tickets. | are selling tickets.",
        "noTickets": "All tickets that this person offered for sale are already sold, or currently being bought by someone.",
        "others": "Tickets offered by others:"
      },
      "resaleItem": {
        "price": "{count} biglietto per {price} | {count} biglietti per {price}",
        "priceUpsell": "For {price} each | {count} for {price} | {count} for {price} each",
        "seller": "Offered by {name}",
        "justSold": {
          "warning": "Sold just now!",
          "message": "Someone beat you to it"
        }
      },
      "resaleHeader": {
        "title": "Tickets in this shop are sold out!",
        "description": "However: anyone with tickets can offer them for sale at anytime on our Ticket Market!"
      },
      "feesInfo": {
        "title": "Resale market fees explained",
        "description": "A {percentage} service fee per ticket is applied.",
        "descriptionWithFixedFees": "A {percentage} service fee plus {fixed} transaction costs per ticket is applied.",
        "buyerFee": "Buyer fee",
        "totalPrice": "Price you pay",
        "footerWithFixedFees": "For the seller, a {percentage} service fee plus {fixed} transaction costs per ticket is applied. This is already included in your price.",
        "footer": "For the seller, a {percentage} service fee per ticket is applied. This is already included in your price"
      }
    },
    "tickets": {
      "soldout": "Soldout",
      "ticketsLeft": "{left} rimasti",
      "tutorial": "Bisogno di aiuto?",
      "upsell": "Extra"
    },
    "footer": {
      "maxTickets": "Massimo {amount} biglietti per persona | Massimo {amount} biglietti per persona",
      "userMaxTickets": "Non puoi ordinare più ulteriori biglietti | Puoi ordinare {amount} ulteriori biglietti | Puoi ordinare {amount} ulteriori biglietti",
      "maxTicketsLeft": "di {amount} massimo",
      "actionOrder": "Ordina",
      "actionNext": "Next"
    },
    "state": {
      "past": {
        "title": "Questo evento ha già avuto luogo",
        "description": "Non ci sono più biglietti disponibili."
      },
      "soldoutWithoutResale": {
        "title": "Tutti i biglietti sono (attualmente) esauriti",
        "description": "Altri biglietti potrebbero essere nuovamente venduti nel prossimo futuro."
      }
    },
    "datepicker": {
      "toggle": "Select a different day",
      "legend": {
        "title": "Pick a day to buy your tickets",
        "soldout": "These days are currently sold out",
        "hasEvents": "Tickets are still available on these days"
      },
      "shopList": {
        "shopCount": "There are {count} events this day",
        "description": "Pick an event to buy your tickets"
      },
      "noEvents": {
        "titleMonth": "No events this month",
        "descriptionMonth": "There are no events this month. Use the calendar to select a different month and browse the agenda.",
        "titleDay": "No events on this day",
        "descriptionDay": "There are no events on this day. Use the calendar to select a different day and browse the agenda."
      }
    },
    "loadingMessage": {
      "wait": "Please wait a moment",
      "contacting": "Contacting shop",
      "browser": "Do not close your browser",
      "fetching": "Fetching available tickets",
      "starting": "Starting shop",
      "populating": "Populating shop",
      "opening": "Opening shop"
    },
    "wallet-connect": {
      "notification": {
        "title": "Check your eligibility to buy tickets",
        "description": {
          "main": "This ticket shop is accessible if you hold the required <a target=\"_blank\" href=\"https://support.guts.tickets/hc/en-001/articles/4410619860242-What-is-an-Event-Collectible\">digital collectible(s)</a> in your wallet.",
          "login": "Please login with your mobile number and verify ownership of your wallet that holds the collectible(s), to check your eligibility.",
          "verifyAccess": "Please verify ownership of your wallet that holds the collectible(s), to check your eligibility."
        },
        "button": {
          "login": "LOG IN",
          "verify": "VERIFY ACCESS"
        }
      },
      "connect": {
        "title": "Connect your wallet first!",
        "description": "To access the shop, you need to hold a collectible from one of the following collections:",
        "button": "Connect wallet"
      },
      "searching": {
        "title": "Checking Collectibles in your wallet..."
      },
      "notFound": {
        "title": "We didn't find the required Collectibles in your wallet",
        "description": "If you think this is an error, please try connecting to another wallet to re-check your eligibility",
        "button": "Connect another wallet"
      },
      "sign": {
        "title": "Confirm you’re the owner of the Wallet",
        "description": "We need to verify ownership of the connected wallet in order to give you access to this shop",
        "button": "Sign via wallet"
      },
      "success": {
        "title": "You have access to the Shop!",
        "description": "We have successfully verified ownership of the provided wallet that holds the required collectible(s) to access the shop",
        "button": "Go to Shop"
      },
      "connectedWallet": "Connected wallet",
      "previousWallet": "Previously connected wallet",
      "disconnect": "Disconnect",
      "searched": "Searched Collections",
      "results": "Result",
      "status": {
        "not-found": "Not found",
        "found": "Found",
        "no-collections": "Error: no collections to check were found."
      }
    }
  },
  "account": {
    "head": {
      "title": "Account"
    },
    "login": {
      "title": "Verifica il tuo numero di telefono",
      "description": "Inserisci il tuo numero di telefono, incluso il codice del tuo paese. Riceverai un codice di accesso tramite un messaggio di testo.",
      "phonePlaceholder": "Il tuo numero di telefono",
      "focusPhonePlaceholder": "+39123456789",
      "actionVerify": "Verifica",
      "searchCountryCode": "Ricerca tramite codice paese",
      "selectCountry": "Select your country",
      "mobileInputDescription": "Must include your country-code, for example +1"
    },
    "callUser": {
      "title": "Haven't received a code?",
      "description": "Our robot can give you a call and read your verification code out loud.",
      "incorrectNumber": "My phone number is not correct",
      "action": "Call me on {phone}",
      "calling": "You will be called now"
    },
    "verify": {
      "title": "Verifica il tuo messaggio di testo",
      "description": "Un messaggio di testo è stato inviato al {number}. Inserisci il codice di 4 cifre.",
      "triesLeft": "Il tuo codice non è corretto. Puoi riprovare ancora {times}.",
      "time": "{tries} volta | {tries} volte",
      "requestCodeCountdown": "Resending is available in {countdown} seconds."
    },
    "captcha": {
      "notice": "This feature is protected by reCAPTCHA and the Google {privacy} and {tos} apply.",
      "privacy": "Privacy Policy",
      "tos": "Terms of Service",
      "title": "Please verify you are human",
      "description": "Tick the box below to prove you are a not a bot. We might occasionally ask this to prevent spammers & scammers."
    }
  },
  "profile": {
    "head": {
      "title": "Il mio profilo"
    },
    "title": "Il mio profilo",
    "description": "Il tuo profilo potrebbe essere verificato ad un evento, quindi assicurati che le informazioni siano corrette.",
    "mandatory": "Fields marked with * are mandatory.",
    "additonalInfoTitle": "Additional Information",
    "additonalInfoDescription": "The event organiser requests the following additional information.",
    "terms": "Sono d'accordo con <a href=@:brand.termsUrl target=\"_blank\">termini e condizioni</a> di @:brand.nameShort <small>(obbligatorio)</small>",
    "optIn": "A @:brand.nameShort è concesso di condividere i miei dati con gli artisti per i quali ho comprato i biglietti e di informarmi in merito a news e prossimi eventi, come indicato in <a href=@:brand.privacyPolicyUrl target=\"_blank\">our privacy policy</a>.",
    "firstName": "Nome *",
    "lastName": "Cognome *",
    "email": "Indirizzo email *",
    "gender": "Gender",
    "male": "Maschio",
    "female": "Femmina",
    "other": "Altro",
    "birthdate": "Data di nascita",
    "dateProperties": {
      "day": "day",
      "month": "month",
      "year": "year"
    },
    "country": "Paese",
    "state": "State",
    "zipcode": "Codice postale",
    "address": "Streetname & housenumber",
    "city": "City",
    "nft_address": {
      "wallet_address": "Wallet address",
      "title": "Connect your own Web3 Wallet?",
      "description_off": "Receive event collectibles on your own existing Web3 wallet. By default a Web3 wallet will be created for you.",
      "description_on": "Receive event collectibles on your own Web3 wallet by connecting it once below. Collectibles are distributed on the Polygon blockchain.",
      "description_connected": "Your event collectibles will be sent to the wallet connected below on the Polygon blockchain. Reset to switch to a different wallet.",
      "help": "New to Web3 wallets?  <a href=\"{url}\" target=\"_blank\">Learn more</a> or simply skip this!",
      "overview": "You'll receive your digital collectable NFTs on the following Ethereum / Polygon wallet address",
      "reset": "Reset",
      "edit": "Edit",
      "confirmation": {
        "title": "Are you sure?",
        "description": "This will allow you to set a different wallet address for any future digital collectible claims. If you don't connect any wallet, we'll create one for you."
      }
    },
    "actionSave": "Salva"
  },
  "order": {
    "head": {
      "title": "Il tuo ordine"
    },
    "overview": {
      "credits": {
        "applied": "Credits applied",
        "optIn": "Use my credits to pay for this order",
        "appliedDescription": "You have {amount} credits attached to this order.",
        "availableDescription": "You have {amount} of remaining credits in your account. These credits are valid for all events of {org} and expire on {expirationDate}."
      },
      "organisationName": "Organizzatore",
      "termsOptIn": {
        "url": "I agree with the <a href=\"{url}\" target=\"_blank\">terms and conditions</a> of {name} <small>(mandatory)</small>",
        "custom": "{label} - <a target=\"_blank\" href=\"{url}\">Terms and conditions</a> <small>(mandatory)</small>",
        "text": "<span>I agree with the terms and conditions of {name} <small>(mandatory)</small></span><p>{text}</p>"
      },
      "privacyOptIn": {
        "url": "I want to be informed of events and promotions and agree with the <a href=\"{url}\" target=\"_blank\">privacy statement</a> of {name}",
        "custom": "{label} - <a target=\"_blank\" href=\"{url}\">Privacy policy</a>",
        "text": "<span>I want to be informed of events and promotions and agree with the privacy statement of {name}</span><p>{text}</p>"
      },
      "splitMarketTerms": "I accept the resale market <a href=\"{url}\" target=\"_blank\">terms and conditions</a> of GET International",
      "timeLeft": "Da ora hai 15 minuti per completare il tuo ordine",
      "actionFree": "Conferma",
      "actionPaid": "Paga"
    },
    "giftcard": {
      "title": "I have a gift card or voucher code",
      "description": "Enter your code below to redeem",
      "action": "Redeem",
      "placeholder": "For example: ‘321BDFK874’",
      "label": "Giftcard or voucher code",
      "modal": {
        "label": "Valid giftcard / voucher code",
        "title": "Value",
        "description": "Here is what will happen",
        "description-one": "Your account will be credited with {amount}",
        "description-two-a": "You can use these credits on",
        "description-two-b": "all events of {org}",
        "description-three": "They will expire on",
        "action": "Redeem",
        "cancel": "Not now"
      },
      "error": {
        "empty": "Please enter a valid giftcard or voucher code",
        "invalid": "This code is invalid",
        "expired": "This code has expired",
        "used": "This code has already been used",
        "wrong_organizer": "This code cannot be used for {org}",
        "generic": "Something went wrong, code cannot be redeemed"
      }
    },
    "payment": {
      "ideal": "Scegli la tua banca ideale",
      "other": "Scegli il tuo metodo di pagamento",
      "noPaymentOptions": "Non ci sono metodi di pagamento disponibili al momento. Prova a ricaricare questa pagina. Se il problema persiste, <a href=\"@:global.supportUrl\" target=\"_blank\">Per favore contattaci</a>."
    },
    "paymentLoader": {
      "redirecting": "Stai per essere reindirizzato a {method}...",
      "error": "{method} Sembra ci sia un problema.\nRiprova o scegli un altro metodo di pagamento..."
    },
    "status": {
      "pending": {
        "title": "Il tuo ordine sta per essere processato..."
      },
      "paid": {
        "title": "Ordine riuscito!",
        "description": "La conferma è stata inviata a {email} - assicurati di controllare la tua cartella di spam.",
        "descriptionAnonymous": "An order confirmation has been sent to you - if it's not there, be sure to check your spam. ",
        "actionTickets": "Vai ai biglietti online"
      },
      "timeout": {
        "title": "Resisti...",
        "description": "Purtroppo non siamo stati ancora in grado di confermare il tuo pagamento. Appena lo faremo, riceverai conferma a {email}."
      },
      "error": {
        "title": "Ordine fallito...",
        "description": "Qualcosa è andato storto mentre si completava il tuto ordine. Puoi provare a ordinare i biglietti un'altra volta.",
        "actionBack": "Torna all'evento"
      }
    },
    "app": {
      "title": "I biglietti di @:brand.nameShort funzionano sul tuo smartphone, installa l'app!",
      "description": "Benefici di utilizzare l'app:",
      "imageAlt": "@:brand.nameShort App portafoglio eventi per iPhone & Android",
      "featureList": [
        "I biglietti digitali nell'applicazione ti fanno accedere all'evento.",
        "Vendi facilmente i biglietti se non puoi partecipare.",
        "Regala i biglietti o condividili con i tuoi amici."
      ]
    }
  },
  "tickets": {
    "index": {
      "head": {
        "title": "I miei eventi"
      },
      "title": "I miei prossimi eventi",
      "noEventsTitle": "Non ci sono prossimi eventi",
      "noEventsDescription": "Non hai nessun biglietto per i prossimi eventi."
    },
    "details": {
      "head": {
        "title": "Biglietti"
      },
      "actionBack": "I miei eventi",
      "inactive": {
        "title": "Il tuo QR code è bloccato in maniera sicura",
        "description": "Apparirà automaticamente il {unlockDate}."
      },
      "active": {
        "title": "Fai scansionare questo QR code all'evento",
        "description": "Questo codice funziona per tutti i tuoi biglietti e i tuoi amici."
      },
      "notScannable": {
        "title": "Nessun biglietto da verificare",
        "description": "Tutti i tuoi biglietti sono già stati verificati, venduti o rimborsati."
      },
      "noCopies": "Screenshot o stampe del biglietto non funzionano",
      "seating": {
        "seatsAppear": "I posti numerati vengono assegnati il giorno prima dell'evento.",
        "entrance": "Entrata",
        "section": "Section",
        "row": "Fila",
        "seats": "Posto"
      },
      "group": {
        "info": "Gruppo di {activeUsers}/{groupSize}",
        "leave": "Lascia",
        "confirmLeave": "Sei sicuro di voler lasciare questo gruppo?"
      },
      "livestream": {
        "watchStream": "Watch now",
        "upsell": {
          "shopLink": "Go to shop",
          "warning": "Note: you did not purchase any tickets",
          "description": "You only purchased extras. If you also intended on buying tickets, you can still do so."
        },
        "inactive": {
          "title": "Accesso al video",
          "description": "Il video sarà accessibile proprio qui il {unlockDate}."
        },
        "unauthorized": {
          "title": "No access to livestream",
          "description": "You have not purchased access to this livestream"
        },
        "active": {
          "title": "Il video è iniziato!",
          "description": "You have exclusive access to this livestream."
        }
      },
      "footer": {
        "title": "Vuoi condividere o vendere i tuoi biglietti?",
        "description": "Installa l'applicazione mobile e fai di più."
      }
    }
  },
  "invite": {
    "title": "Invito da",
    "shareTickets": "{ticketAmount} biglietto condiviso con tutti | {ticketAmount} biglietti condivisi con tutti",
    "group": "{personAmount} persona già nel gruppo | {personAmount} persone già nel gruppo",
    "confirmAccept": "Sei sicuro di volerti unire a questo gruppo?",
    "confirmDecline": "Sei sicuro di non volerti unire a questo gruppo?",
    "warning": "<strong>Accetta solo se conosci e ti fidi {inviterName}!</strong> Tutti in questo gruppo hanno esattamente gli stessi diritti - incluso il diritto di invitare altre persone e di vendere tutti i biglietti per questo evento, compreso il tuo!",
    "invite": "Invito da",
    "accept": "accetta",
    "decline": "rifiuta"
  },
  "pendingPayment": {
    "title": "Warning: Pending payment",
    "pay": "Pay",
    "wait": "Wait",
    "existingPayment": "You already started a payment for this order.",
    "confirmationEmail": "You’ll receive a confirmation email shortly if you completed this payment.",
    "confirm": "Do you want start a new payment or wait?"
  },
  "reservationInvitation": {
    "claimAction": "Claim tickets",
    "status": {
      "title": "Great, the tickets are yours!"
    }
  }
}
