{
  "global": {
    "language": "English",
    "actionClose": "Close",
    "actionBack": "Back",
    "actionCancel": "Cancel",
    "optional": "optional",
    "head": {
      "title": "@:brand.name",
      "description": "Want to sell your own honest tickets? @:brand.nameShort eliminates ticket fraud and scalping using innovative technology. From theatre shows to concerts or livestreams: with @:brand.nameShort selling and reselling tickets is simple, transparent and secure.",
      "twitter": "@:brand.twitter",
      "event": {
        "title": "Tickets for {title}",
        "open": "Order your honest tickets for {title}, {date} in {location} on @:brand.name",
        "queue": "Order your honest tickets for {title} on @:brand.name",
        "resale": "Check all tickets on our Ticket Market for {title}, {date} in {location} on @:brand.name",
        "reseller": "My tickets are up for sale for {title}, {date} in {location} on @:brand.name. Get 'em before they're gone!"
      }
    },
    "fullscreen": "Go fullscreen for a better experience",
    "cookiesConsent": {
      "description": "We use cookies for a better experience.",
      "actionAccept": "Accept cookies",
      "actionMore": {
        "name": "Read more"
      }
    },
    "logout": {
      "logoutText": "Log out",
      "logoutConfirmText": "Are you sure? When you log in again you'll receive a new access code."
    },
    "navigation": {
      "account": "Account",
      "eventList": "Events",
      "myTickets": "My tickets",
      "login": "Login",
      "profile": "Profile"
    }
  },
  "brand": {
    "name": "#.name",
    "nameShort": "#.nameShort",
    "twitter": "#.twitter",
    "homepageUrl": "#.homepageUrl",
    "homepageUrlShort": "#.homepageUrlShort",
    "supportUrl": "#.supportUrl",
    "livestreamSupportUrl": "#.livestreamSupportUrl",
    "privacyPolicyUrl": "#.privacyPolicyUrl",
    "termsUrl": "#.termsUrl",
    "androidAppstoreUrl": "#.androidAppstoreUrl",
    "appleAppstoreUrl": "#.appleAppstoreUrl"
  },
  "validation": {
    "errors": {
      "required": "This field can't be blank",
      "email": "This email address appears to be invalid",
      "phoneNumber": "This is not a valid mobile number",
      "rateLimit": {
        "message": "Too many verification attempts.",
        "minutes": "Please try again in 2 minutes.",
        "seconds": "Please try again in {seconds} seconds."
      },
      "globalBlock": {
        "message": "Too many verification attempts.",
        "minutes": "Please try again in a few minutes.",
        "seconds": "Please try again in {seconds} seconds."
      },
      "birthdate": "This birthdate appears to be invalid"
    }
  },
  "messages": {
    "saveProfile": "Profile successfully saved!",
    "seated": "If you bought seated tickets, you will receive your seat numbers at least 24 hours prior to the event, by e-mail and in the @:brand.nameShort app!",
    "seatsAssigned": "Note: If you are buying seated tickets from different people, we can't guarantee that your seats will be together."
  },
  "errors": {
    "default": "Oops! Something went wrong...<br/>You could <a onClick='window.location.reload(false)'>refresh this page</a> and try again.",
    "notStarted": "The ticket sale hasn't started yet. Please check if your device is set to the right time and date.",
    "invalidURL": "This access-link has already been used or is invalid.",
    "saveProfile": "Error: Could not save profile information.",
    "exceeding_limits": "You are exceeding the maximum number of tickets you're allowed to buy.",
    "token_different_user": "If you logged in with a different mobile number than the one provided when entering the queue, please use the refresh button to re-enter with the new correct number.",
    "token_expired": "Your session for this shop has expired, please use the refresh button to re-enter",
    "paymentCanceled": "You've canceled your payment. Feel free to retry.",
    "paymentFailed": "Your payment failed. Feel free to retry.",
    "invalid_order_state": "Your order has expired or was already paid. You can re-order tickets if they're available.",
    "orderNotFound": "We are sorry, but your session has expired. Please <a onClick='window.location.reload(false)'>refresh this page</a> to continue.",
    "user_cannot_share": "This account has no permission to share tickets.",
    "already_in_group": "You're already part of a group for this event. Try leaving the other group first.",
    "user_cannot_leave": "You can't leave because you started this group.",
    "group_is_full": "You can't join this group because it's already full!",
    "expired_code": "This invite has expired. Ask your friend for a new one.",
    "invalid_code": "This invite code is invalid.",
    "system_unavailable": "Our service is temporarily unavailable at the moment due to technical issues. We are currently working on a solution. We apologize for the inconvenience.",
    "payment": {
      "canceledOrFailed": "Your payment was canceled or refused by the issuer. Your order is still valid. Feel free to retry.",
      "genericError": "Something went wrong with the payment service provider. If the issue persists, payments may not be possible at this moment. Please try again later."
    },
    "reservationInvitation": {  
      "invalid_state": "I am sorry, there is no valid invitation associated with this link!",
      "claimed": "This invitation has already been claimed!",
      "expired": "This invitation is no longer valid!",
      "unkown_error": "Something went wrong! Please try again later." 
    } 
  },
  "notFound": {
    "head": {
      "title": " @:global.head.title",
      "complement": "Page not found"
    },
    "title": "This page can't be found",
    "description": "You might be looking for an event (pre)sale that hasn't started yet. If so, please try again later! Want to know more about @:brand.name?",
    "action": "Go to @:brand.homepageUrlShort"
  },
  "appstore": {
    "footer": "Install the @:brand.name app on your smartphone. The easiest way to get in!",
    "action": "Install app",
    "install": {
      "title": "Install the @:brand.name mobile app",
      "description": "Choose your type of smartphone to continue to the app store:",
      "forAndroid": "Install @:appstore.android.name app",
      "forIos": "Install @:appstore.ios.name app",
      "alsoAndroid": "Also available for @:appstore.android.name",
      "alsoIos": "Also available for @:appstore.ios.name"
    },
    "android": {
      "name": "Android",
      "store": "Google Play Store",
      "description": "Discover on @:appstore.android.name",
      "url": "@:brand.androidAppstoreUrl"
    },
    "ios": {
      "name": "iPhone (iOS)",
      "store": "Apple App Store",
      "description": "Download in the @:appstore.ios.name",
      "url": "@:brand.appleAppstoreUrl"
    }
  },
  "queue": {
    "start": {
      "queue": {
        "header": {
          "title": "Enter the event queue",
          "description": "Your tickets will be linked to your mobile number: double-check your details!"
        },
        "actionEnter": "Enter queue"
      },
      "preQueue": {
        "header": {
          "title": "Sale starts on {day} {month} at {time}",
          "description": "Join the pre-queue and you will receive a random position for the ticket sale at {time}."
        },
        "actionEnter": "Enter pre-queue"
      },
      "form": {
        "phonePlaceholder": "Your mobile number",
        "emailPlaceholder": "Your email address",
        "focusPhonePlaceholder": "+31612345678",
        "focusEmailPlaceholder": "emailaddress{'@'}gmail.com"
      },
      "privacy": {
        "description": "By entering your details, you join the queue and you agree with {0}.",
        "name": "our privacy policy"
      }
    },
    "allEventsPast": "This event has expired and tickets are no longer available.",
    "countdown": {
      "positionInfoZero": "It's almost your turn, hold on...",
      "positionInfoOne": "There are only {numberInFront} people in front of you.",
      "positionInfoMultiple": "There are currently {numberInFront} people in front of you.",
      "calculatePosition": "Calculating your position",
      "approx": "approx",
      "minutes": "minutes left | minute left | minutes left",
      "hour": "More than 1 hour...",
      "soldout": "no tickets",
      "calculateChance": "Wow you got here fast! We're calculating the speed of the sale and will show your chances in a minute.",
      "single": "Unfortunately, there are {noTickets} available anymore.",
      "groupSingle": "Unfortunately, there are {noTickets} available anymore for your favorite event. | Unfortunately, there are {noTickets} available anymore for your favorite events.",
      "groupMultiple": "Unfortunately, there are {noTickets} available anymore for {soldOutCount} out of {favoritesCount} of your favorite events.",
      "groupEmtpy": "Select your favorite events and choose one when it's your turn.",
      "selectFav": "Select favorites",
      "changeFav": "Adjust favorites"
    },
    "eventList": {
      "otherData": "All events",
      "soldout": "Sold out",
      "allEvents": "View all events",
      "favoriteEvents": "Your favorites",
      "search": "Filter on location",
      "showsIn": "No events in | 1 event in | {count} events in",
      "other": "Other locations",
      "modal": {
        "title": "Select your favorite event(s)",
        "subtitle": "When it's your turn, your favorite events will be shown first. Easy!"
      },
      "few_available": "almost sold out",
      "no_available": "sold out",
      "tickets_in_carts": "sold out"
    },
    "preQueue": {
      "starting": {
        "title": "Assigning position...",
        "description": "You'll get a random position in the queue. This way everyone has a fair chance for tickets."
      },
      "waiting": {
        "title": "Counting down to {time}",
        "description": "When the sale queue opens you'll get a random position and you have to wait until it's your turn to buy tickets."
      },
      "footer": {
        "noNumber": "If your phone number is incorrect you won't be able to buy tickets: {link} to re-enter.",
        "description": "You registered with mobile number {number}. If this is incorrect you won't be able to buy tickets: {link} to re-enter.",
        "link": "Go here"
      }
    },
    "paused": {
      "queuePaused": "Queue paused",
      "details": "Because of high demand we are currently not letting anyone go through the queue. Just a moment please..."
    },
    "turn": {
      "single": {
        "title": "Your turn!",
        "description": "You can now order your tickets",
        "order": "Order"
      },
      "group": {
        "title": "Your turn!",
        "description": "Select an event. You can always go back and choose a different one."
      }
    },
    "open": {
      "title": "Choose an event"
    },
    "notStarted": {
      "description": "The sale hasn't started yet. It will begin on {date} at {time}.",
      "action": "View events",
      "support": "Please check out our {0} for more information about the ordering process.",
      "supportLink": "support page"
    },
    "soldout": {
      "title": "This event is currently sold out",
      "details": "Bad news: All available tickets are sold out. There is a chance that tickets are put up for sale, so check back later."
    },
    "access": {
      "description": "This shop is only accessible using access links provided by the event organizer. Once you have claimed access to a shop, you will always be able to return.",
      "checkAccess": "Check Access"
    }
  },
  "shop": {
    "global": {
      "tickets": "Tickets | Ticket | Tickets",
      "total": "Total",
      "free": "Free",
      "serviceCosts": "service costs",
      "serviceCostsLong": "Including @:shop.global.serviceCosts",
      "serviceCostsShort": "Incl. @:shop.global.serviceCosts",
      "buyerFee": "Includes {fee} fee",
      "floorplan": "Floorplan",
      "back": "Edit your order"
    },
    "tabs": {
      "info": "Info",
      "seated": "& seats"
    },
    "eventInfo": {
      "openDoors": "Open",
      "startTime": "Start",
      "endTime": "End",
      "openMap": "Open on map",
      "timezone": "Note: Timezone is {timezone}"
    },
    "floorplan": {
      "description": "Select a section on the floor plan to buy your tickets.",
      "action": "Order using floor plan",
      "list": "Or select your tier and section from the list below."
    },
    "resale": {
      "actionBack": "Back to main shop",
      "resaleLink": "Check Ticket Market",
      "available": "{count} available",
      "originalPrice": "Lowest price {price}",
      "showMore": "Show more tickets",
      "checkMore": "Check for more tickets",
      "refreshMessage": "The current list might contain outdated ticket information. Want to refresh the list?",
      "emptyMessage": "Currently no one is offering tickets through our Ticket Market. Check back later!",
      "noOthers": "Currently, the only available tickets are offered by {seller}. Please check above, or refresh.",
      "noOthersFallback": "Currently, the only available tickets are listed above. You can also refresh.",
      "resellerItems": {
        "manyResellers": "{names} and {others_count} others",
        "isSelling": "is selling tickets. | are selling tickets.",
        "noTickets": "This person doesn't have any tickets for sale (anymore).",
        "others": "Tickets offered by others:"
      },
      "resaleItem": {
        "price": "Tickets for {price} each | {count} ticket for {price} | {count} tickets for {price} each",
        "priceUpsell": "For {price} each | {count} for {price} | {count} for {price} each",
        "seller": "Offered by {name}",
        "justSold": {
          "warning": "Sold just now!",
          "message": "Someone beat you to it"
        }
      },
      "resaleHeader": {
        "title": "Tickets in this shop are sold out!",
        "description": "However: anyone with tickets can offer them for sale at anytime on our Ticket Market!"
      },
      "feesInfo": {
        "title": "Resale market fees explained",
        "description": "A {percentage} service fee per ticket is applied.",
        "descriptionWithFixedFees": "A {percentage} service fee plus {fixed} transaction costs per ticket is applied.",
        "buyerFee": "Buyer fee",
        "totalPrice": "Price you pay",
        "footerWithFixedFees": "For the seller, a {percentage} service fee plus {fixed} transaction costs per ticket is applied. This is already included in your price.",
        "footer": "For the seller, a {percentage} service fee per ticket is applied. This is already included in your price"
      }
    },
    "tickets": {
      "soldout": "Soldout",
      "ticketsLeft": "{left} left",
      "tutorial": "Need help?",
      "upsell": "Extra"
    },
    "footer": {
      "maxTickets": "Max {amount} ticket per person | Max {amount} tickets per person",
      "userMaxTickets": "You can't order more tickets | You can order {amount} more ticket | You can order {amount} more tickets",
      "maxTicketsLeft": "of {amount} max",
      "actionOrder": "Order",
      "actionNext": "Next"
    },
    "state": {
      "past": {
        "title": "This event has already taken place",
        "description": "There are no more tickets available."
      },
      "soldoutWithoutResale": {
        "title": "There are currently no tickets available",
        "description": "Tickets might be put up for sale, so check back later."
      }
    },
    "datepicker": {
      "toggle": "Select a different day",
      "legend": {
        "title": "Pick a day to buy your tickets",
        "soldout": "These days are currently sold out",
        "hasEvents": "Tickets are still available on these days"
      },
      "shopList": {
        "shopCount": "There are {count} events this day",
        "description": "Pick an event to buy your tickets"
      },
      "noEvents": {
        "titleMonth": "No events this month",
        "descriptionMonth": "There are no events this month. Use the calendar to select a different month and browse the agenda.",
        "titleDay": "No events on this day",
        "descriptionDay": "There are no events on this day. Use the calendar to select a different day and browse the agenda."
      }
    },
    "loadingMessage": {
      "wait": "Please wait a moment",
      "contacting": "Contacting shop",
      "browser": "Do not close your browser",
      "fetching": "Fetching available tickets",
      "starting": "Starting shop",
      "populating": "Populating shop",
      "opening": "Opening shop"
    },
    "wallet-connect": {
      "notification": {
        "title": "Check your eligibility to buy tickets",
        "description": {
          "main": "This ticket shop is accessible if you hold the required <a target=\"_blank\" href=\"https://support.guts.tickets/hc/en-001/articles/4410619860242-What-is-an-Event-Collectible\">digital collectible(s)</a> in your wallet.",
          "login": "Please login with your mobile number and verify ownership of your wallet that holds the collectible(s), to check your eligibility.",
          "verifyAccess": "Please verify ownership of your wallet that holds the collectible(s), to check your eligibility."
        },
        "button": {
          "login": "LOG IN",
          "verify": "VERIFY ACCESS"
        }
      },
      "connect": {
        "title": "Connect your wallet first!",
        "description": "To access the shop, you need to hold a collectible from one of the following collections:",
        "button": "Connect wallet"
      },
      "searching":{ 
        "title": "Checking Collectibles in your wallet..."
      },        
      "notFound": {
        "title": "We didn't find the required Collectibles in your wallet",
        "description": "If you think this is an error, please try connecting to another wallet to re-check your eligibility",
        "button": "Connect another wallet"
      },
      "sign": {
        "title": "Confirm you’re the owner of the Wallet",
        "description": "We need to verify ownership of the connected wallet in order to give you access to this shop",
        "button": "Sign via wallet"
      },
      "success": {
        "title": "You have access to the Shop!",
        "description": "We have successfully verified ownership of the provided wallet that holds the required collectible(s) to access the shop",
        "button":"Go to Shop"
      },
      "connectedWallet": "Connected wallet",
      "previousWallet": "Previously connected wallet",
      "disconnect": "Disconnect",
      "searched": "Searched Collections",
      "results": "Result",
      "status": {
        "not-found": "Not found",
        "found": "Found",
        "no-collections": "Error: no collections to check were found."
      }
    }
  },
  "account": {
    "head": {
      "title": "Account"
    },
    "login": {
      "title": "Verify your mobile number",
      "description": "Enter your mobile number including your country code. You'll receive an access code via text message.",
      "phonePlaceholder": "Your mobile number",
      "focusPhonePlaceholder": "+44123456789",
      "actionVerify": "Verify",
      "searchCountryCode": "Search for your country code",
      "selectCountry": "Select your country",
      "mobileInputDescription": "Must include your country-code, for example +1"
    },
    "callUser": {
      "title": "Haven't received a code?",
      "description": "Our robot can give you a call and read your verification code out loud.",
      "incorrectNumber": "My phone number is not correct",
      "action": "Call me on {phone}",
      "calling": "You will be called now"
    },
    "verify": {
      "title": "Check your mobile phone",
      "description": "A text message has been sent to {number}. Enter the 4-digit code.",
      "triesLeft": "Your code isn't correct. You can try again {times}.",
      "time": "{tries} time | {tries} times",
      "requestCodeCountdown": "Resending is available in {countdown} seconds."
    },
    "captcha": {
      "notice": "This feature is protected by reCAPTCHA and the Google {privacy} and {tos} apply.",
      "privacy": "Privacy Policy",
      "tos": "Terms of Service",
      "title": "Please verify you are human",
      "description": "Tick the box below to prove you are a not a bot. We might occasionally ask this to prevent spammers & scammers."
    }
  },
  "profile": {
    "head": {
      "title": "My profile"
    },
    "title": "My profile",
    "description": "This information might be verified at an event, so make sure it's correct.",
    "mandatory": "Fields marked with * are mandatory.",
    "additonalInfoTitle": "Additional Information",
    "additonalInfoDescription": "The event organiser requests the following additional information.",
    "terms": "I agree to the <a href=@:brand.termsUrl target=\"_blank\">terms and conditions</a> of @:brand.nameShort <small>(mandatory)</small>",
    "optIn": "@:brand.nameShort is allowed to share my data with artists I buy tickets for and inform me about news and upcoming events, as stated in <a href=@:brand.privacyPolicyUrl target=\"_blank\">our privacy policy</a>.",
    "firstName": "First name *",
    "lastName": "Last name *",
    "email": "Email address *",
    "gender": "Gender",
    "male": "Male",
    "female": "Female",
    "other": "Other",
    "birthdate": "Date of birth",
    "dateProperties": {
      "day": "day",
      "month": "month",
      "year": "year"
    },
    "country": "Country",
    "state": "State",
    "zipcode": "Zip code",
    "address": "Street name & house number",
    "city": "City",
    "nft_address": {
      "wallet_address": "Wallet address",
      "title": "Connect your own Web3 Wallet?",
      "description_off": "Receive event collectibles on your own existing Web3 wallet. By default a Web3 wallet will be created for you.",
      "description_on": "Receive event collectibles on your own Web3 wallet by connecting it once below. Collectibles are distributed on the Polygon blockchain.",
      "description_connected": "Your event collectibles will be sent to the wallet connected below on the Polygon blockchain. Reset to switch to a different wallet.",
      "help": "New to Web3 wallets?  <a href=\"{url}\" target=\"_blank\">Learn more</a> or simply skip this!",
      "overview": "You'll receive your digital collectable NFTs on the following Ethereum / Polygon wallet address",
      "reset": "Reset",
      "edit": "Edit",
      "confirmation": {
        "title": "Are you sure?",
        "description": "This will allow you to set a different wallet address for any future digital collectible claims. If you don't connect any wallet, we'll create one for you."
      }
    },
    "actionSave": "Save"
  },
  "order": {
    "head": {
      "title": "Your order"
    },
    "overview": {
      "credits": {
        "applied": "Credits applied",
        "optIn": "Use my credits to pay for this order",
        "appliedDescription": "You have {amount} credits attached to this order.",
        "availableDescription": "You have {amount} of remaining credits in your account. These credits are valid for all events of {org} and expire on {expirationDate}."
      },
      "organisationName": "the organiser",
      "termsOptIn": {
        "url": "I agree with the <a href=\"{url}\" target=\"_blank\">terms and conditions</a> of {name} <small>(mandatory)</small>",
        "custom": "{label} - <a target=\"_blank\" href=\"{url}\">Terms and conditions</a> <small>(mandatory)</small>",
        "text": "<span>I agree with the terms and conditions of {name} <small>(mandatory)</small></span><p>{text}</p>"
      },
      "privacyOptIn": {
        "url": "I want to be informed of events and promotions and agree with the <a href=\"{url}\" target=\"_blank\">privacy statement</a> of {name}",
        "custom": "{label} - <a target=\"_blank\" href=\"{url}\">Privacy policy</a>",
        "text": "<span>I want to be informed of events and promotions and agree with the privacy statement of {name}</span><p>{text}</p>"
      },
      "splitMarketTerms": "I accept the resale market <a href=\"{url}\" target=\"_blank\">terms and conditions</a> of GET International",
      "timeLeft": "You now have 15 minutes to complete your order",
      "actionFree": "Confirm",
      "actionPaid": "Pay"
    },
    "giftcard": {
      "title": "I have a gift card or voucher code",
      "description": "Enter your code below to redeem",
      "action": "Redeem",
      "placeholder": "For example: ‘321BDFK874’",
      "label": "Giftcard or voucher code",
      "modal": {
        "label": "Valid giftcard / voucher code",
        "title": "Value",
        "description": "Here is what will happen",
        "description-one": "Your account will be credited with {amount}",
        "description-two-a": "You can use these credits on",
        "description-two-b": "all events of {org}",
        "description-three": "They will expire on",
        "action": "Redeem",
        "cancel": "Not now"
      },
      "error": {
        "empty": "Please enter a valid giftcard or voucher code",
        "invalid": "This code is invalid",
        "expired": "This code has expired",
        "used": "This code has already been used",
        "wrong_organizer": "This code cannot be used for {org}",
        "generic": "Something went wrong, code cannot be redeemed"
      }
    },
    "payment": {
      "ideal": "Pick your iDeal bank",
      "other": "Pick your payment method",
      "noPaymentOptions": "There are no methods of payment available at the moment. Try refreshing this page. If the problem persists, <a href=\"@:brand.supportUrl\" target=\"_blank\">Please contact us</a>."
    },
    "paymentLoader": {
      "redirecting": "You are being redirected to {method}...",
      "error": "{method} seems to be having issues.\nTry again or select a different payment method."
    },
    "status": {
      "pending": {
        "title": "Your order is being processed..."
      },
      "paid": {
        "title": "Order successful!",
        "description": "An order confirmation has been sent to {email} - if it's not there, be sure to check your spam. ",
        "descriptionAnonymous": "An order confirmation has been sent to you - if it's not there, be sure to check your spam. ",
        "actionTickets": "Go to online tickets"
      },
      "timeout": {
        "title": "Hang in there...",
        "description": "Unfortunately we haven't been able to confirm your payment yet. As soon as we do, you'll receive a confirmation at {email}."
      },
      "error": {
        "title": "Order failed...",
        "description": "Something went wrong while completing your order. Please try again.",
        "actionBack": "Back to event"
      }
    },
    "app": {
      "title": "Tickets from @:brand.nameShort work on your smartphone, so install the app!",
      "description": "Benefits of using the app:",
      "imageAlt": "@:brand.name Wallet app for iPhone & Android",
      "featureList": [
        "The digital tickets in the app get you into the event.",
        "Easily sell tickets if you can't make it.",
        "Give away tickets or share them with friends."
      ]
    }
  },
  "tickets": {
    "index": {
      "head": {
        "title": "My events"
      },
      "title": "My upcoming events",
      "noEventsTitle": "No upcoming events",
      "noEventsDescription": "You don't have any tickets for upcoming events."
    },
    "details": {
      "head": {
        "title": "Tickets"
      },
      "actionBack": "My Events",
      "inactive": {
        "title": "Your QR code is securely locked",
        "description": "It will appear automatically on {unlockDate}."
      },
      "active": {
        "title": "Have this QR code scanned at the event",
        "description": "This code works for all your tickets and friends."
      },
      "notScannable": {
        "title": "No tickets to check in",
        "description": "All your tickets are either checked-in, sold or invalidated (to credits)."
      },
      "noCopies": "Copies or prints won't work",
      "seating": {
        "seatsAppear": "Seat numbers are assigned the day before the event.",
        "entrance": "Entrance",
        "section": "Section",
        "row": "Row",
        "seats": "Seat"
      },
      "group": {
        "info": "Group of {activeUsers}/{groupSize}",
        "leave": "Leave",
        "confirmLeave": "Are you sure you want to leave this group ?"
      },
      "livestream": {
        "watchStream": "Watch now",
        "upsell": {
          "shopLink": "Go to shop",
          "warning": "Note: you did not purchase any tickets",
          "description": "You only purchased extras. If you also intended on buying tickets, you can still do so."
        },
        "inactive": {
          "title": "Access to livestream",
          "description": "The livestream will be accessible right here on {unlockDate}."
        },
        "unauthorized": {
          "title": "No access to livestream",
          "description": "You have not purchased access to this livestream"
        },
        "active": {
          "title": "Livestream has started!",
          "description": "You have exclusive access to this livestream."
        }
      },
      "footer": {
        "title": "Want to share or sell your tickets?",
        "description": "Install the mobile app and do more."
      }
    }
  },
  "invite": {
    "title": "Invite from",
    "shareTickets": "{ticketAmount} ticket shared with everyone | {ticketAmount} tickets shared with everyone",
    "group": "{personAmount} person already in group | {personAmount} people already in group",
    "confirmAccept": "Are you sure you want to join this group ?",
    "confirmDecline": "Are you sure you don't want to join this group ?",
    "warning": "<strong>Only accept if you know and trust {inviterName}!</strong> Everyone in this group has exactly the same rights - including the right to invite others and to sell all tickets for this event, including yours!",
    "invite": "Invite from",
    "accept": "accept",
    "decline": "decline"
  },
  "pendingPayment": {
    "title": "Warning: Pending payment",
    "pay": "Pay",
    "wait": "Wait",
    "existingPayment": "You already started a payment for this order.",
    "confirmationEmail": "You’ll receive a confirmation email shortly if you completed this payment.",
    "confirm": "Do you want start a new payment or wait?"
  },
  "reservationInvitation": {
    "claimAction": "Claim tickets",
    "status": {
      "title": "Great, the tickets are yours!"
    }
  }
}
